@font-face {
  font-family: 'Poppins ExtraBold';
  src: local('Poppins-ExtraBold'), url('/src/assets/Poppins-ExtraBold.ttf') format('opentype');
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: local('Poppins-SemiBold'), url('/src/assets/Poppins-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: 'SpaceMono Regular';
  src: local('SpaceMono-Regular'), url('/src/assets/SpaceMono-Regular.ttf') format('opentype');
}

@font-face {
  font-family: 'SF Pro Rounded, Semibold';
  src: local('SF-Pro-Rounded-Semibold'), url('/src/assets/SF-Pro-Rounded-Semibold.otf') format('opentype');
}

@font-face {
  font-family: 'SF Pro Rounded, Medium';
  src: local('SF-Pro-Rounded-Medium'), url('/src/assets/SF-Pro-Rounded-Medium.otf') format('opentype');
}

body {
  margin: 0;
  font-family: 'SpaceMono Regular';
  background: transparent linear-gradient(131deg, #5668FE 0%, #F355F9 100%) 0% 0% no-repeat padding-box;
  min-height: 100vh;
}

@media(max-width:576px) {
  body {
    background: transparent linear-gradient(180deg, #5668FE 0%, #F355F9 16%, #556BF5 100%) 0% 0% no-repeat padding-box;
  }
}