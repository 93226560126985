.modal.show {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: flex-start;
}

.mobile-modal-content {
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20px;
}

.mobile-modal-text {
  font-size: 16px;
  display: inline-block
}

.modal .link {
  color: #EF27F7;
  margin-left: 5px;
  font-family: 'SF Pro Rounded Medium';
}

.modal .link:hover {
  text-decoration: none;
}

.modal-body {
  padding: 15px 30px;
}

.modal-text {
  font-family: 'SF Pro Rounded, Medium';
}

.color-white {
  color: #fff !important;
  text-align: left;
  margin: 10px;
}

.use-the-app-btn {
  font-family: 'SF Pro Rounded, Semibold';
  background-color: white !important;
  border: none;
  color: white;
  font-size: 16px;
  text-align: center;
  padding: 10px;
  display: block;
  margin-top: 10px;
  width: 100%;
  color: #103250;
  border-radius: 10px;
}

.pink-bg {
  background-color: #EF27F7;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
}

.mobile-logo {
  border-radius: 8px;
}